import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import NotFound from '../../container/pages/404';
import { PERMISSIONS } from '../../config/permissions';

const Dashboard = lazy(() => import('../../container/dashboard'));
const Maintenance = lazy(() => import('../../container/dashboard/Maintenance'));
const Warehouse = lazy(() => import('../../container/dashboard/Warehouse'));

function DashboardRoutes() {
  const { path } = useRouteMatch();
  const permissions = useSelector((state) => state.auth.user.permissions);

  return (
    <Switch>
      <Route exact path={path} component={Dashboard} />
      {permissions.includes(PERMISSIONS.dashboard.maintenance.value) && <Route exact path={`${path}/workshop`} component={Maintenance} />}
      {permissions.includes(PERMISSIONS.dashboard.maintenance.value) && <Route exact path={`${path}/warehouse`} component={Warehouse} />}
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
}

export default DashboardRoutes;
